<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/12-1400.jpg')" title="Aerobatic Endorsement" subtitle="Serious fun" />
    <div class="program-description">
      <h1>What is an Aerobatic Endorsement?</h1>
      <p>Aerobatic training in the amazing Extra 300.</p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
